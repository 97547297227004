<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import customerServiceImg from '@/core/assets/img/illu-cs.svg'

defineProps<{
  modelValue: boolean
}>()

defineEmits(['update:modelValue'])

const { t } = useI18n()
</script>

<template>
  <Modal size="lg" :modelValue="modelValue" @close="$emit('update:modelValue', false)">
    <div class="flex flex-col gap-4 text-center">
      <img class="w-24 mx-auto" :src="customerServiceImg" />
      <p class="text-lg font-bold">{{ t('customer_service.title') }}</p>
      <p class="flex flex-col text-lg">
        <i18n-t keypath="customer_service.description">
          <a class="text-[1.375rem] text-primary font-semibold my-2" href="tel:03 73 80 80 80"> 03 73 80 80 80 </a>
        </i18n-t>
      </p>
      <hr class="card-separator" />
      <i18n-t tag="p" class="flex flex-col" keypath="customer_service.service_available">
        <span class="font-semibold">{{ t('customer_service.monday_to_friday') }}</span>
      </i18n-t>
      <p>{{ t('customer_service.ps') }}</p>
    </div>
  </Modal>
</template>
