import { AxiosError } from 'axios'
import { useFormError } from '@delupay/web-core/composables'
import { type FormError, defaultFormErrors } from '@/types/errors'

const { safeParseErrors } = useFormError()

export function processFormErrors(error: unknown): FormError[] {

  if (error instanceof AxiosError && error.response?.status === 422) {
    if (error.response.data === '') {
      return [
        {
          attribute: 'none',
          messages: ['No processable messages']
        }
      ]
    }
    return safeParseErrors(error.response.data.errors).errors
  }

  return defaultFormErrors
}

export function resetFormError(errors: FormError[], attribute: string) {
  errors[errors.findIndex((err) => err.attribute === attribute)] = defaultFormErrors[0]
}