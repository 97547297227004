<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div class="text-sm text-right">
    <p>{{ t('login.forget_password.title') }}</p>
    <p class="text-darkgray">
      {{ t('login.forget_password.description') }}
    </p>
  </div>
</template>
