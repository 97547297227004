<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDayjs } from '@/core/composables/useDayjs'

import useTransaction from '@/merchant/composables/useTransaction'
import { useFeatureFlagsStore } from '@/merchant/stores/featureFlags'

import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

import Icon from '@/components/ui/icon/Icon.vue'
import DPhoneNumberInput from '@/core/components/DPhoneNumberInput.vue'
import DQrCodeReader from '@/core/components/DQrCodeReader.vue'

import type { Transaction } from '@/merchant/api/transaction'
import type { Icons } from '@/core/utils/icons'
import { captureException } from '@sentry/vue'

const { can } = useFeatureFlagsStore()

const props = defineProps<{
  transaction: Transaction
}>()

const { t } = useI18n()
const { dayjs } = useDayjs()
const { associateTransaction } = useTransaction()

type Category = {
  ref: 'qr-code' | 'message' | 'scan'
  title: string
  icon: Icons
}

const categories = computed<Category[]>(() => {
  const _categories: Category[] = [
    { ref: 'qr-code', title: t('transaction.process_card.tabs.qr-code'), icon: 'qr-code' },
    { ref: 'message', title: t('transaction.process_card.tabs.message'), icon: 'message' },
  ]

  if (can('merchant_can_cashin_by_qrcode')) {
    _categories.push({ ref: 'scan', title: t('transaction.process_card.tabs.scan'), icon: 'scan' })
  }

  return _categories
})

const formatDay = (date: Date) => {
  if (dayjs(date).isToday()) return t('common.today')
  if (dayjs(date).isYesterday()) return t('common.yesterday')
  return dayjs(date).toISOString()
}

const canAccesToUserCamera = ref(true)
const cameraAccessLoading = ref(false)

const onQrCodeReaderInit = async (promise: any) => {
  try {
    cameraAccessLoading.value = true
    await promise
  } catch (error: any) {
    // NotFoundError => no suitable camera device installed
    // NotSupportedError => page is not served over HTTPS (or localhost)
    // NotReadableError => maybe camera is already in use
    // OverconstrainedError => did you requested the front camera although there is none?
    // StreamApiNotSupportedError => browser seems to be lacking features
    if (error.name === 'NotAllowedError') {
      canAccesToUserCamera.value = false
    }
  } finally {
    cameraAccessLoading.value = false
  }
}

const detailsRows = [
  { label: t('transaction.process_card.table_headers.date'), value: formatDay(new Date(props.transaction.createdAt)) },
  {
    label: t('transaction.process_card.table_headers.hour'),
    value: dayjs(props.transaction.createdAt).format('HH:mm'),
  },
  { label: t('transaction.process_card.table_headers.reference'), value: props.transaction.reference },
]

const phoneNumberInput = ref<
  {
    alpha2: string
    isValid: boolean
    phoneNumberFormatted: string | undefined
  }[]
>()

const handleAssignTransactionWithSms = async () => {
  if (!phoneNumberInput.value) {
    console.error('[TransactionProcess] No phone number input')
    captureException(new Error('[TransactionProcess] No phone number input'))
    return
  }

  // @TODO: refactor me to get rid of this ref hack...
  if (!phoneNumberInput.value[0].isValid) {
    console.error('[TransactionProcess] Phone number input is not valid')
    captureException(new Error('[TransactionProcess] Phone number input is not valid'))
    return
  }

  await associateTransaction({
    phoneNumber: phoneNumberInput.value[0].phoneNumberFormatted,
    countryCode: phoneNumberInput.value[0].alpha2,
  })
}

const handleAssignTransactionWithCustomerQrCode = async (customerRefUrl: string) => {
  const parts = customerRefUrl.split('/a/')
  const [, uuid] = parts

  await associateTransaction({
    uuid,
  })
}
</script>

<template>
  <TabGroup>
    <TabList class="flex space-x-1 rounded-full bg-gray-200 p-0.5">
      <Tab v-for="category in categories" as="template" :key="category.ref" v-slot="{ selected }">
        <button
          :class="[
            'w-full rounded-full py-2.5 text-sm font-semibold leading-5 flex items-center justify-center transition-all',
            'ring-white/60 ring-offset-2 ring-offset-dp-400 focus:outline-none',
            selected ? 'bg-primary text-white shadow' : 'text-black hover:bg-dp-400 hover:text-white',
          ]"
        >
          {{ category.title }}
          <Icon :icon="category.icon" class="ml-2 h-4 w-4" />
        </button>
      </Tab>
    </TabList>

    <TabPanels class="flex flex-col gap-6 transition-all duration-1000">
      <section class="w-full flex flex-col items-center">
        <span class="text-4xl">{{ transaction.amount.localized }}</span>
        <span class="font-semibold tracking-wider">{{ transaction.qrCodeCode.split('-').join(' - ') }}</span>
      </section>
      <TabPanel
        v-for="(category, idx) in categories"
        :key="idx"
        :class="[
          'rounded-xl bg-grey-50 flex flex-col gap-6',
          'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none',
        ]"
      >
        <template v-if="category.ref === 'qr-code'">
          <section class="flex justify-center">
            <Image :src="transaction.qrCodeUrl" class="object-contain object-center h-52 w-52" />
          </section>
        </template>
        <template v-if="category.ref === 'message'">
          <div class="p-4 sm:p-6 rounded-3xl border-2 border-border self-stretch mt-7">
            <d-phone-number-input
              ref="phoneNumberInput"
              class="w-full"
              :auto-focus="false"
              @enter="handleAssignTransactionWithSms"
            />
            <Button
              class="w-full mt-4 relative"
              size="lg"
              @click="handleAssignTransactionWithSms"
              :disabled="!(phoneNumberInput && phoneNumberInput[0] && phoneNumberInput[0].isValid)"
            >
              {{ t('common.send') }}
              <Icon class="absolute right-4 h-4 w-4" icon="send" />
            </Button>
          </div>

          <div class="text-center max-w-md">
            <p class="font-semibold">{{ t('transaction.process_card.sms.title') }}</p>
            <p>{{ t('transaction.process_card.sms.description') }}</p>
          </div>
        </template>
        <template v-if="category.ref === 'scan'">
          <div class="w-full max-w-100 flex justify-center items-center">
            <div class="relative w-full pb-[100%] rounded-3xl overflow-hidden bg-lightgray -mt-3">
              <div v-if="cameraAccessLoading" class="absolute inset-0 flex items-center justify-center">
                <loading-icon class="text-primary h-20 w-20" />
              </div>
              <div v-show="canAccesToUserCamera" class="absolute inset-0">
                <DQrCodeReader @decode="handleAssignTransactionWithCustomerQrCode" @init="onQrCodeReaderInit" />
              </div>
              <div
                v-if="canAccesToUserCamera && !cameraAccessLoading"
                class="absolute inset-0 flex justify-center items-center"
              >
                <div class="absolute inset-0 bg-grey-900 bg-opacity-50" />
                <div class="rounded-2xl h-2/3 w-2/3 border-4 border-white relative cam-reticle" />
                <p class="text-white absolute w-full text-center top-5/6 leading-none -mt-3 px-1/3">
                  Centrer le QR code
                </p>
              </div>
              <div v-if="!canAccesToUserCamera" class="flex items-center justify-center absolute inset-0">
                <div class="text-center flex flex-col gap-4">
                  <div class="font-bold">{{ t('transaction.process_card.camera_not_allowed.title') }}</div>
                  <div>{{ t('transaction.process_card.camera_not_allowed.description') }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <p class="text-center font-semibold">{{ t('transaction.process_card.scan.title') }}</p> -->
        </template>
        <TwRows v-if="category.ref !== 'scan'" :rows="detailsRows" class="self-stretch" />

        <d-message v-if="category.ref === 'qr-code'" class="w-full" color="primary">
          <div>
            <p>{{ t('transaction.process_card.qr-code.message_1') }}</p>
            <p>{{ t('transaction.process_card.qr-code.message_2') }}</p>
          </div>
        </d-message>
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<style scoped>
.cam-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 25rem auto;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 25rem auto;
}
.cam-reticle {
  clip-path: polygon(
    15% -1%,
    15% 15%,
    85% 15%,
    85% -1%,
    101% -1%,
    101% 15%,
    85% 15%,
    85% 85%,
    101% 85%,
    101% 101%,
    85% 101%,
    85% 85%,
    15% 85%,
    15% 101%,
    -1% 101%,
    -1% 85%,
    15% 85%,
    15% 15%,
    -1% 15%,
    -1% -1%
  );
}
</style>
