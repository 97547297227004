<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import DMessage from '@/core/components/DMessage.vue'
import DButton from '@/core/components/DButton.vue'

const { t } = useI18n()
</script>

<template>
  <DMessage icon="circle-warning" color="warning" :light="false">
    <div class="flex flex-col sm:flex-row gap-4 flex-grow">
      <div class="flex flex-col items-start">
        <p class="font-bold text-lg">{{ t('messages.suspention.title') }}</p>
        <p>{{ t('messages.suspention.description') }}</p>
      </div>
      <div class="flex-grow flex justify-end self-end">
        <DButton text="Contacter le service client" size="sm" />
      </div>
    </div>
  </DMessage>
</template>
