<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

import bus from '@/core/bus/eventBus'
import { importantNotificationColors } from '@/core/utils/system-notification'

import { canDiscardNotifications, setSystemNotificationRead } from '@/merchant/api/systemNotifications'
import { useSystemNotificationStore } from '@/merchant/stores/systemNotifications'

import { Button } from '@delupay/web-core/components'

const systemNotification = useSystemNotificationStore()
systemNotification.init()
const { items, importantNotifications } = storeToRefs(systemNotification)

const setRead = async (uuid: string) => {
  const r = await setSystemNotificationRead(uuid)
  if (!r) return
  await systemNotification.get()
}

const { t } = useI18n()
</script>

<template>
  <div v-if="importantNotifications.length" class="flex flex-col gap-4">
    <d-message
      v-for="item in importantNotifications"
      :key="item"
      :color="importantNotificationColors[items[item].key as keyof typeof importantNotificationColors]"
      :light="false"
      icon-size="6"
      icon="circle-warning"
      :can-discard="canDiscardNotifications.includes(items[item].key)"
      @discard="setRead(item)"
    >
      <div class="grid md:grid-cols-[1fr,min-content] gap-x-4 gap-y-4 flex-grow">
        <p class="text-lg font-semibold leading-tight md:col-span-2">
          {{ t(`overdue.${items[item].key}.title`) }}
        </p>
        <div class="flex flex-col gap-4">
          <i18n-t :keypath="`overdue.${items[item].key}.text`" tag="p">
            <template #amount>
              <span class="font-semibold">
                {{ `${items[item].data?.overdraft?.localized || ''}` }}
              </span>
            </template>
            <template #line>
              <span class="block text-lg mt-3 leading-tight font-semibold">
                {{ `Montant : ${items[item].data?.overdraft?.localized || ''}` }}
              </span>
            </template>
          </i18n-t>
          <div v-if="items[item].data?.recoveryIban" class="flex flex-wrap gap-2 sm:col-span-2 md:col-span-1">
            <d-input
              :model-value="items[item].data?.recoveryIban"
              locked
              class="flex-grow text-base max-w-100"
              :label="t('overdue.overdue_iban_input_label')"
            />
            <d-clipboard-button
              :hideText="false"
              :text="t('overdue.overdraft_detected.copy_button')"
              :value="items[item].data?.recoveryIban ?? 'xxxx'"
            />
          </div>
        </div>
        <Button class="self-start" size="lg" variant="white" @click="bus.emit('customerservice.show')">
          {{ t('common.contact_client_service') }}
        </Button>
      </div>
    </d-message>
  </div>
</template>
