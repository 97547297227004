<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { transactionStateIcon, transactionStateColor } from '@/core/utils/transaction'

import Badge from '@/components/ui/badge/Badge.vue'

import type { TransactionState } from '@/merchant/api/transaction'
import type { Icons } from '@/core/utils/icons'

const props = defineProps<{
  state?: TransactionState
  customState?: {
    icon?: Icons
    color?: string
    text?: string
  }
}>()

const color = computed(
  () => props.customState?.color || (props.state && `text-white bg-${transactionStateColor[props.state.key]}`),
)
const icon = computed(() => props.customState?.icon || (props.state && transactionStateIcon[props.state.key]))

const { t } = useI18n()
</script>

<template>
  <Badge size="sm" :class="color" color="none">
    <Icon :icon="icon" class="bg-transparent text-white rounded-full" />
    <span class="ml-1"> {{ customState?.text || (state && t(`transaction.activity_state.${state.key}`)) }}</span>
  </Badge>
</template>
