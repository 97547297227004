import { ref, type Ref } from 'vue'

import { defineStore } from 'pinia'

import { getFeatures } from '@/merchant/api/featuresFlags'

export const featureKeys = [
  'merchant_can_change_payment_types',
  'merchant_can_delete_account',
  'merchant_checkout_only',
  'merchant_can_cashin_by_qrcode',
  'qr_code',
  'merchant_can_update_commission_rate',
  'merchant_one_to_many',
  'merchant_checkout_type',
] as const

type FeatureKey = (typeof featureKeys)[number]

export const useFeatureFlagsStore = defineStore('feature-flags', () => {
  const active: Ref<FeatureKey[]> = ref([])
  const inactive: Ref<FeatureKey[]> = ref([])
  const isLoaded = ref(false)

  const init = async () => {
    const features = await getFeatures()

    active.value = features.active
    inactive.value = features.inactive

    isLoaded.value = true
  }

  const can = (featureKey: FeatureKey) => {
    const _can = (_featureKey: FeatureKey) => (active.value.find((feature) => feature === _featureKey) ? true : false)

    if (isLoaded.value) return _can(featureKey)
    else init().then(() => _can(featureKey))
  }

  return {
    isLoaded,

    active,
    inactive,

    init,

    can,
    cannot: (featureKey: FeatureKey) => !can(featureKey),
  }
})
