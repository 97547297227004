<script setup lang="ts">
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    level?: string
  }>(),
  {
    level: 'low',
  },
)

const { t } = useI18n()
</script>

<template>
  <d-message icon="circle-warning" color="warning" :light="false">
    <div v-if="['high', 'very_high'].includes(level)" class="flex flex-col sm:flex-row gap-4 flex-grow">
      <div class="flex flex-col items-start">
        <p class="font-bold text-lg">{{ t('messages.high_scoring.title') }}</p>
        <p>{{ t('messages.high_scoring.description') }}</p>
      </div>
      <div class="flex-grow flex justify-end self-end">
        <d-button text="Contacter le service client" size="sm" />
      </div>
    </div>
    <i18n-t v-else keypath="settings.beneficial_owners.identity_verifications.step.owners.forbidden" tag="p">
      <template #link>
        <d-link color="dark">{{ t('common.contact_client_service') }}</d-link>
      </template>
    </i18n-t>
  </d-message>
</template>
