/**
 * Load global styles
 */
import './core/assets/styles/main.css'

import App from './App.vue'
const app = createApp(App)

/**
 * Install pinia
 *
 * @see https://pinia.esm.dev/
 */
import { createPinia } from 'pinia'
const pinia = createPinia()
app.use(pinia)

/**
 * Install i18n
 *
 * @see https://github.com/intlify/bundle-tools/blob/main/packages/unplugin-vue-i18n/README.md
 */
import i18n from './core/modules/i18n'
app.use(i18n)

/**
 * Install vue-router
 *
 * @see https://github.com/posva/unplugin-vue-router
 */
import router from './core/modules/router'
app.use(router)

/**
 * Install VueQuery
 *
 * @see https://tanstack.com/query/v4/docs/vue/overview - v4 @TODO update to v5
 */
import { VueQueryPlugin } from '@tanstack/vue-query'
app.use(VueQueryPlugin)

/**
 * Install VueToastification
 *
 * @see https://github.com/Maronato/vue-toastification
 */
import { Toast, config } from './core/modules/toastification'
app.use(Toast, config)

/**
 * Install VueSentry
 *
 * @see https://docs.sentry.io/platforms/javascript/guides/vue/
 */
import { install as installSentry } from './core/modules/sentry'
app.use(installSentry, { router })

/**
 * Install VueGtag
 *
 * @see https://github.com/gtm-support/vue-gtm
 */
import { install as installGtm } from './core/modules/gtm'
app.use(installGtm, { router })

app.mount('#app')

/**
 * Load middlewares
 */
// import { nProgressBeforeEach, nProgressAfterEach } from './core/modules/router/middlewares/nProgressMiddleware'
import { authMiddleware } from './core/modules/router/middlewares/authMiddleware'
import { featuresMiddleware } from './core/modules/router/middlewares/featuresMiddleware'
import { onboardingMiddleware } from './core/modules/router/middlewares/onboardingMiddleware'

router.beforeEach(authMiddleware)
router.beforeEach(featuresMiddleware)
router.beforeEach(onboardingMiddleware)

// router.beforeEach(async (to, from, next) => {
//   nProgressBeforeEach(to, from, next)
//
//   next()
// })
//
// router.afterEach(async (to, from) => {
//   nProgressAfterEach(to, from)
// })
