<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

import bus from '@/core/bus/eventBus'
import { formatISO } from '@/core/utils/date'

import { deletePointOfSaleClosure } from '@/merchant/api/pointOfSale'
import { useUserStore } from '@/merchant/stores/user'
import { useRegistration } from '@/merchant/hooks/registration'
import { useSystemNotificationStore } from '@/merchant/stores/systemNotifications'

import type { ImportantNotificationKey } from '@/merchant/api/systemNotifications'
import type { AppColor } from '@/core/utils/colors'
import type { AbsoluteAmount } from '@/merchant/api/transaction'

type PaymentState = 'debit' | 'credit' | 'done' | 'acted' | 'debit_issued' | 'debit_rejected'
type ClosureState = 'pending' | 'acted'

const { loadRegistration } = useRegistration()
const user = useUserStore()
const { apiPayload, currentPointOfSale } = storeToRefs(user)
const systemNotification = useSystemNotificationStore()
const { recoveryIban, importantNotifications, items } = storeToRefs(systemNotification)
const loading = ref(false)

const { t } = useI18n()

const balance = computed<AbsoluteAmount | undefined>(() => currentPointOfSale.value?.balance)

const closureState = computed<ClosureState | undefined>(() => {
  if (!user.closureNoticeUntil) return
  return new Date(user.closureNoticeUntil).getTime() < Date.now() ? 'acted' : 'pending'
})
const debitState = computed<ImportantNotificationKey | undefined>(() => {
  if (closureState.value === 'pending') return
  const trackState: ImportantNotificationKey[] = ['debit_issued', 'debit_rejected']
  const itemId = importantNotifications.value.find((id) =>
    trackState.includes(items.value[id].key as ImportantNotificationKey),
  )
  return itemId ? (items.value[itemId].key as ImportantNotificationKey) : undefined
})
const paymentState = computed<PaymentState | ImportantNotificationKey>(() => {
  const pending = closureState?.value === 'pending'
  if (debitState.value) return debitState.value
  if (typeof balance.value?.positive !== 'boolean') return pending ? 'done' : 'acted'
  return balance.value?.positive ? 'credit' : 'debit'
})
const messageColor = computed<AppColor>(() => {
  if (debitState.value === 'debit_rejected') return 'danger'
  if (closureState.value === 'acted' && !debitState.value) return 'green-600'
  return 'warning'
})

const accountClosure = computed(() =>
  user.closureScheduledAt ? formatISO(user.closureScheduledAt.toString(), 'dd/MM/yyyy') : '',
)

const onCancelClick = async () => {
  loading.value = true
  const r = await deletePointOfSaleClosure(apiPayload.value)
  loading.value = false
  if (!r) return
  await loadRegistration()
}
</script>

<template>
  <d-message :color="messageColor" :light="false" icon="circle-refund" icon-size="6">
    <div class="flex flex-col items-start gap-4">
      <div>
        <template v-if="closureState">
          <p class="font-bold">{{ t(`user.account_closure.notification.title.${closureState}`) }}</p>
          <i18n-t v-if="!debitState" :keypath="`user.account_closure.notification.text.${closureState}`" tag="p">
            <template #accountdate>{{ accountClosure }}</template>
          </i18n-t>
        </template>
        <i18n-t
          :class="{ 'font-semibold': !debitState }"
          :keypath="`user.account_closure.notification.payment_state.${paymentState}`"
          tag="p"
        >
          <template v-if="balance" #amount>{{ balance?.localized }}</template>
        </i18n-t>
      </div>
      <div class="flex gap-4 items-center">
        <d-clipboard-button
          v-if="recoveryIban"
          :value="recoveryIban"
          variant="border"
          size="sm"
          :text="t('user.account_closure.copy_iban')"
        />
        <d-button
          v-if="closureState === 'pending'"
          :text="t('user.account_closure.notification.cancel')"
          size="sm"
          :loading="loading"
          @click="onCancelClick"
        />
        <d-button
          v-else
          :text="t('user.account_closure.notification.contact_customer_service')"
          size="sm"
          @click="bus.emit('customerservice.show')"
        />
      </div>
    </div>
  </d-message>
</template>
