export interface FormError {
  attribute: string,
  messages: string[]
}

export const defaultFormErrors = [
  {
    attribute: '',
    messages: []
  }
]
