<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import DMessage from '@/core/components/DMessage.vue'

defineProps<{
  missingDocuments?: {
    eaiCertificate: boolean
    substantiating: boolean
  }
}>()

const { t } = useI18n()
</script>

<template>
  <DMessage color="warning" icon="circle-warning" icon-size="6" :light="false">
    <div class="flex flex-col sm:flex-row gap-4 flex-grow">
      <div class="flex flex-col items-start gap-2">
        <p class="font-bold text-lg leading-snug">Éléments requis</p>
        <p>{{ t('messages.missing_document.title') }}</p>
        <ul class="list-disc pl-4">
          <li v-if="missingDocuments && missingDocuments.eaiCertificate">
            {{ t('messages.missing_document.list_1') }}
          </li>
          <li v-if="missingDocuments && missingDocuments.substantiating">
            {{ t('messages.missing_document.list_2') }}
          </li>
        </ul>
      </div>
      <div class="flex-grow flex justify-end self-end">
        <d-button text="Se rapprocher du service client" size="sm" />
      </div>
    </div>
  </DMessage>
</template>
