<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import useTransaction from '@/merchant/composables/useTransaction'

import Button from '@/components/ui/button/Button.vue'
import TransactionProcess from '@/merchant/components/transactions/TransactionProcess.vue'
import TransactionPreview from './TransactionPreview.vue'
import Modal from '@/components/ui/modal/Modal.vue'

import type { Transaction } from '@/merchant/api/transaction'

const props = defineProps<{
  transaction: Transaction
  modelValue: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const router = useRouter()
const { t } = useI18n()
const {
  //  cancelTransaction,
  //  cancelTransactionLoading,
  renewTransaction,
  renewTransactionLoading,
  refreshTransaction,
  reset,
} = useTransaction()

onMounted(async () => {
  if (['pending', 'associated'].includes(props.transaction.state.key)) {
    await refreshTransaction({ polling: true })
  }
})

// const handleCancelTransaction = async () => {
//   await cancelTransaction({ reference: props.transaction.reference })
//   reset()
//   emit('update:modelValue', false)
// }

const handleRenewTransaction = async () => {
  await renewTransaction({ transaction: props.transaction })
  await refreshTransaction({ polling: true })
}

const handleShowDetails = () => {
  reset()
  emit('update:modelValue', false)
  router.push({
    name: 'transactions.show',
    params: { reference: props.transaction.reference },
  })
}

const close = () => {
  reset()
  emit('update:modelValue', false)
}
</script>

<template>
  <Modal :modelValue="modelValue" @close="close" size="lg" :no-auto-close="transaction.state.key === 'pending'">
    <section class="flex flex-col gap-4">
      <p class="text-2xl font-semibold text-center" v-if="transaction.state.key === 'pending'">
        {{ t('transaction.process_card.title') }}
      </p>
      <TransactionProcess v-if="transaction.state.key === 'pending'" :transaction="transaction" />
      <TransactionPreview v-else :transaction="transaction" />
      <div class="flex w-full gap-4 mt-6">
        <div v-if="transaction.state.key !== 'pending'" class="flex w-full gap-x-4">
          <Button size="lg" @click="handleShowDetails" variant="white" class="w-full">
            {{ t('transaction.preview_card.cta.detail') }}
          </Button>
          <Button size="lg" class="w-full" @click="handleRenewTransaction" :disabled="renewTransactionLoading">{{
            t('transaction.preview_card.cta.renew')
          }}</Button>
        </div>
        <div v-if="transaction.state.key === 'pending'" class="flex w-full gap-2">
          <!-- <Button -->
          <!--   size="lg" -->
          <!--   color="outline-red" -->
          <!--   class="w-full" -->
          <!--   @click="handleCancelTransaction" -->
          <!--   :disabled="cancelTransactionLoading" -->
          <!--   >{{ t('transaction.process_card.cta.cancel') }}</Button -->
          <!-- > -->

          <Button size="lg" class="w-full" @click="close">{{ t('transaction.process_card.cta.set_waiting') }}</Button>
        </div>
      </div>
    </section>
  </Modal>
</template>
